/** @deprecated use {@link WarehouseTransferStatus } */
export enum WAREHOUSE_STATE {
  CANCELLED = "CANCELLED",
  DRAFT = "DRAFT",
  NEW = "NEW",
  RECEIVED = "RECEIVED",
  SUBMITTED = "SUBMITTED",
}

export const WarehouseTransferStatusObject = {
  CANCELLED: "CANCELLED",
  DRAFT: "DRAFT",
  NEW: "NEW",
  RECEIVED: "RECEIVED",
  SUBMITTED: "SUBMITTED",
} as const;

export type WarehouseTransferStatus =
  (typeof WarehouseTransferStatusObject)[keyof typeof WarehouseTransferStatusObject];
