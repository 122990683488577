import { generateUUID } from "@/helpers/uuid";
import { Moment } from "moment";

export class ReceiveWarehouseTransferFormStateLine {
  rowId: string = generateUUID();

  id: string | null = null;
  part: string | undefined = undefined;
  partId: string | undefined = undefined;
  serialNumber: string | undefined = undefined;
  totalQty = 0;
  uom: string | undefined = undefined;
  uomId: string | undefined = undefined;
  qtyReceive = 0;
  qtyOutstanding = 0;
}

export class ReceiveWarehouseTransferFormState {
  receiveDate: Moment | null = null;
  note = "";
  receiveWarehouseTransferLines: ReceiveWarehouseTransferFormStateLine[] = [];
}
