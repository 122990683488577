import { AssetTransferResponseDto } from "@/models/interface/asset-transfer";

export function buildAssetTransferResponseDto(): AssetTransferResponseDto {
  return {
    createdBy: "",
    createdDate: "",
    date: "",
    documentNo: "",
    driverName: "",
    expeditionAddress: "",
    expeditionId: "",
    expeditionName: "",
    from: "",
    fromId: "",
    id: "",
    modifiedBy: "",
    modifiedDate: "",
    note: "",
    status: "",
    to: "",
    toId: "",
    truckNumber: "",
    units: [],
    url: "",
  };
}
