






















































































































































































import { buildAssetTransferResponseDto } from "@/builder/asset-transfer/AssetTransferResponseDtoBuilder";
import { ReceiveAssetTransferModal } from "@/components/AssetTransfer";
import dateFormat from "@/filters/date.filter";
import { useBlob } from "@/hooks";
import MNotification from "@/mixins/MNotification.vue";
import { AssetTransferResponseDto } from "@/models/interface/asset-transfer";
import { AssetTransferUnitFormState } from "@/models/interface/asset-transfer/AssetTransferDetailFormState.interface";
import { assetTransferService } from "@/services/asset-transfer.service";
import printJS from "print-js";
import { Component, Mixins } from "vue-property-decorator";

@Component({
  components: {
    ReceiveAssetTransferModal,
  },
})
export default class AssetTransferDetail extends Mixins(MNotification) {
  get isSubmitted(): boolean {
    return this.assetTransferResponseDTO.status === "SUBMITTED";
  }

  assetTransferResponseDTO: AssetTransferResponseDto =
    buildAssetTransferResponseDto();
  units: AssetTransferUnitFormState[] = [];

  columns = [
    {
      title: this.$t("lbl_number_short"),
      dataIndex: "no",
      width: "64px",
      customRender: (no: number) => `${no}.` || "-",
    },
    {
      title: this.$t("lbl_unit_code"),
      dataIndex: "unitCode",
      customRender: (text: string) => text || "-",
    },
    {
      title: this.$t("lbl_serial_number"),
      dataIndex: "serialNo",
      customRender: (text: string) => text || "-",
    },
    {
      title: this.$t("lbl_equipment"),
      dataIndex: "equipment",
      customRender: (text: string) => text || "-",
    },
    {
      title: this.$t("lbl_type"),
      dataIndex: "type",
      customRender: (text: string) => text || "-",
    },
    {
      title: this.$t("lbl_model"),
      dataIndex: "model",
      customRender: (text: string) => text || "-",
    },
    {
      title: this.$t("lbl_specification"),
      dataIndex: "specification",
      customRender: (text: string) => text || "-",
    },
    {
      title: this.$t("lbl_quantity"),
      dataIndex: "qty",
      customRender: (text: string) => text || "-",
    },
    {
      title: this.$t("lbl_list_accessories"),
      dataIndex: "accessories",
      customRender: (text: string) => text || "-",
    },
  ];

  loading = {
    fetchDetail: false,
    cancel: false,
    print: false,
    receive: false,
  };

  modal = {
    visible: false,
    open(): void {
      this.visible = true;
    },
    close(): void {
      this.visible = false;
    },
  };

  findAssetTransferDetail(assetTransferId: string): void {
    this.loading.fetchDetail = true;
    assetTransferService
      .getAssetTransferDetail(assetTransferId)
      .then(res => {
        this.assetTransferResponseDTO = { ...res, date: dateFormat(res.date) };
        this.units = res.units.map((unit, index) => ({
          ...unit,
          no: index + 1,
          accessories: unit.listAccessories.join(", "),
        }));
      })
      .finally(() => {
        this.loading.fetchDetail = false;
      });
  }

  downloadAttachment(attachment: string, unitCode: string) {
    const link = document.createElement("a");
    link.href = this.$sanitize(attachment);
    link.setAttribute("download", `${unitCode}-attachment`);
    link.click();
    link.remove();
  }

  handleBack(): void {
    this.$router.push({
      name: "asset.transfers",
    });
  }

  handleCancel(): void {
    this.loading.cancel = true;
    assetTransferService
      .cancelAssetTranfer(this.assetTransferResponseDTO.id)
      .then(res => {
        this.showNotifSuccess("notif_update_success", {
          documentNumber: res.documentNo,
        });
        this.findAssetTransferDetail(this.assetTransferResponseDTO.id);
      })
      .finally(() => {
        this.loading.cancel = false;
      });
  }

  handlePrint(): void {
    const { toObjectUrl } = useBlob();

    this.loading.print = true;
    assetTransferService
      .printAssetTransfer(this.assetTransferResponseDTO.id)
      .then(res => {
        printJS(toObjectUrl(res));
      })
      .finally(() => (this.loading.print = false));
  }

  handleReceive(): void {
    this.modal.open();
  }

  handleSuccess(): void {
    this.modal.close();
    this.findAssetTransferDetail(this.assetTransferResponseDTO.id);
  }

  created(): void {
    const id = this.$route.params.id;

    this.findAssetTransferDetail(id);
  }
}
