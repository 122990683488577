import { CreateWarehouseTransferFormState } from "@/models/interface/warehouse-transfer/CreateWarehouseTransferFormState.interface";
import { ReceiveWarehouseTransferFormState } from "@/models/interface/warehouse-transfer/ReceiveWarehouseTransferFormState.interface";
import {
  DetailWarehouseTransfer,
  RequestWarehouseTransfer,
} from "@/models/interface/warehouse.interface";
import moment from "moment";

export class WarehouseTransferMapper {
  static mapRequestWarehouseTransfer(
    state: CreateWarehouseTransferFormState
  ): RequestWarehouseTransfer {
    return {
      attachment: state.attachment || "",
      date: state.date?.format() || "",
      deletedLineIds: state.deletedLineIds || [],
      destinationLocationId: state.to?.key || "",
      notes: state.note || "",
      sourceLocationId: state.from?.key || "",
      transferLines: state.warehouseTransferLines.map(line => ({
        id: line.id,
        productId: line.part?.key || "",
        serialNumber: line.serialNumber?.key || "",
        totalQty: line.totalQty || 0,
        uomId: line.uom?.key || "",
      })),
    };
  }

  static mapCreateWarehouseTransferFormState(
    detail: DetailWarehouseTransfer
  ): CreateWarehouseTransferFormState {
    return {
      attachment: detail.attachment,
      date: moment(detail.date),
      deletedLineIds: [],
      from: {
        key: detail.sourceLocationId,
        label: detail.sourceLocation,
      },
      note: detail.notes,
      to: {
        key: detail.destinationLocationId,
        label: detail.destinationLocation,
      },
      warehouseTransferLines: detail.transferLines.map(line => ({
        rowId: line.id,

        id: line.id,
        part: {
          key: line.product.id,
          label: line.product.name,
        },
        serialNumber: {
          key: line.serialNumber,
          label: line.serialNumber,
        },
        totalQty: line.qty,
        uom: {
          key: line.uom.id,
          label: line.uom.name,
        },

        loadingPartOptions: false,
        partOptions: [],
        usePartOptions: false,

        uomOptions: [],
        loadingUOMOptions: false,

        serialNumberOptions: [],
        loadingSerialNumberOptions: false,
      })),
      status: detail.state,
    };
  }

  static mapReceiveWarehouseTransferFormState(
    detail: DetailWarehouseTransfer
  ): ReceiveWarehouseTransferFormState {
    return {
      note: detail.notes,
      receiveDate: detail.receivedDate ? moment(detail.receivedDate) : null,
      receiveWarehouseTransferLines: detail.transferLines.map(line => ({
        rowId: line.id,
        id: line.id,
        part: line.product.name,
        partId: line.product.id,
        serialNumber: line.serialNumber,
        totalQty: line.qty,
        uom: line.uom.name,
        uomId: line.uom.id,
        qtyReceive: line.receivedQty,
        qtyOutstanding: line.outstandingQty,
      })),
    };
  }

  static mapRequestWarehouseTransferFromReceiveForm(
    detail: DetailWarehouseTransfer,
    formState: ReceiveWarehouseTransferFormState
  ): RequestWarehouseTransfer {
    return {
      attachment: detail.attachment,
      date: moment(detail.date).format(),
      deletedLineIds: [],
      destinationLocationId: detail.destinationLocationId,
      notes: formState.note,
      sourceLocationId: detail.sourceLocationId,
      receivedDate: formState.receiveDate?.format(),
      transferLines: formState.receiveWarehouseTransferLines.map(line => ({
        id: line.id,
        secureId: line.id,
        productId: line.partId || "",
        serialNumber: line.serialNumber || "",
        totalQty: line.totalQty,
        uomId: line.uomId || "",
        receivedQty: line.qtyReceive,
      })),
    };
  }
}
